<template>
  <a-layout class="custom-layout">
    <a-button class="back-btn" size="small" @click="back"><a-icon type="double-left" class="font12" />返回</a-button>
    <div class="batch-content auto">
      <div class="batch-wrap auto">
        <div class="batch-title font16 auto">
          <b>批量查询</b>
        </div>
        <div class="batch-top auto">
          查询说明：请先
          <a-button size="small" @click="downloadHandle">下载模板</a-button>
          ，编辑后再导入
        </div>
        <a-spin :spinning="uploadLoading">
          <div class="batch-upload auto">
            <a-upload-dragger
              :showUploadList="false"
              name="file1"
              :action="config.api + 'user/upload'"
              @change="handleChange"
            >
              <p class="ant-upload-drag-icon excel-icon">
                <a-icon type="file-excel" />
              </p>
              <p class="ant-upload-text excel-text">
                将文件拖拽至此区域
              </p>
              <p class="ant-upload-hint excel-btn">
                <a-button size="small">上传文件</a-button>
              </p>
            </a-upload-dragger>
          </div>
        </a-spin>
      </div>
    </div>
  </a-layout>
</template>

<script>
import { User } from '@/model'
export default {
  data () {
    return {
      uploadId: '',
      uploadLoading: false,
      statusInterval: null
    }
  },
  methods: {
    downloadHandle () {
      window.open(this.config.api + 'user/template', '_blank')
    },
    back () {
      this.$router.go(-1)
    },
    handleChange(info) {
      this.uploadLoading = true
      const status = info.file.status;
      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      if (status === 'done') {
        // console.log(info)
        if (!info.file.response['code']) {
          this.uploadId = info.file.response.data
          this.$cookie.set('batchid', this.uploadId)
          this.$router.push('/user/index')
          // this.statusInterval = setInterval(() => {
          //   this.getBatchUserMesStatus(this.uploadId)
          // }, 1000)
        } else {
          // this.$message.error(info.file.response['description'])
          this.$message.error(this.errMsg[info.file.response['code']])
        }
        this.uploadLoading = false
      } else if (status === 'error') {
        this.uploadLoading = false
        this.$message.error(`${info.file.name} 上传失败`);
      }
    },
    async getBatchUserMesStatus (id) {
      let res = await User.getBatchUserMesStatus(id)
      if (!res['code']) {
        if (res['data'] === 'success') {
          clearInterval(this.statusInterval)
          this.uploadLoading = false
          this.openSuccess(id, 'normal')
          this.getBatchUserMes(id)
        }
        if (res['data'] === 'error') {
          this.uploadLoading = false
          clearInterval(this.statusInterval)
          this.$message.error('处理失败，请重试！');
        }
      } else {
        this.uploadLoading = false
        this.$message.error(this.errMsg[res['code']])
      }
    }
  }
};
</script>

<style scoped>
.batch-content {
  width: 100%;
  height: 100%;
}
.batch-wrap {
  width: 326px;
  margin: 0 auto;
}
.batch-title {
  text-align: center;
  line-height: 62px;
}
.batch-top {
  width: 324px;
  border: 1px solid #91D5FF;
  background: #E6F7FF;
  text-align: center;
  padding: 15px 0;
}
.batch-upload {
  margin-top: 15px;
}
.excel-icon i {
  color: rgba(0,0,0,0.10)!important;
  margin-top: 15px;
}
.excel-text {
  color: rgba(0,0,0,0.25)!important;
  font-size: 14px!important;
  margin-bottom: 10px!important;
}
.excel-btn {
  padding-bottom: 20px;
}

.content-bar {
  padding: 8px 0;
}
.batch-menu {
  width: 100%;
  padding-top: 50px;
}
.batch-menu ul li {
  line-height: 50px;
  cursor: pointer;
  font-size: 16px;
  color: rgba(0,0,0,0.65);
  text-indent: 40px;
}
.batch-menu ul li.cur {
  color: rgba(0,0,0,0.85);
  font-weight: bold;
  background: #E6F7FF;
}

.topline {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background: #6DD400;
}
.topline-red {
  background: #E02020;
}
.topline-yellow {
  background: #F7B500;
}
</style>